@font-face {
  font-family: "Gilroy Light";
  src: url("../src/Fonts/Gilroy-Light.otf");
}
@font-face {
  font-family: "Gilroy ExtraBold";
  src: url("../src/Fonts/Gilroy-ExtraBold.otf");
}

body {
  margin: 0;
  font-family: "Gilroy Light", "Gilroy ExtraBold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfcfc;
}

code {
  font-family: "Gilroy Light", "Gilroy ExtraBold";
}

a {
  font-size: 20px;
}
