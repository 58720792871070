.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background: linear-gradient(
    130.04deg,
    #7b61ff 0%,
    #ffffff 49.48%,
    #7b61ff 100%
  );
}

.contact-button { 
  padding: 20px;
  background-color: #7b61ff; 
  color: white;
  margin-top: 20px;
  border-radius: 20px;
}

.ela-protegida-container {
  margin-top: 20;
  display: flex !important;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

.maria-penha-container {
  margin-top: 20;
  display: flex !important;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
}

.image-div {
  padding: 20px;
}

.paper-card-mpv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 273px;
  margin: 10px;
  justify-content: center;
  cursor: pointer;
}

.paper-card-text {
  color: white;
}

.paper-card-about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 273px;
  margin: 10px;
  justify-content: center;
  cursor: pointer;
}

.sup-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-around;
}

.paper-card-about-us:hover .sobre-nos-overlay {
  opacity: 1;
}

.paper-card-mpv:hover .mpv-overlay {
  opacity: 1;
}

.paper-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.img-cards {
  height: inherit;
  width: inherit;
  z-index: -0;
}

.mpv-overlay {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #883fb573;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.sobre-nos-overlay {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #883fb573;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.img-grid {
  display: flex;
}

.bndes-top-logo {
  margin-top: 20px;
  margin-left: 10px;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 20px;
}

.ilustracao {
  max-width: 90%;
}

.box-imagens {
  display: flex;
}

.left-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-box {
  height: 100%;
}

.partners-box {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
}

.image-layout {
  margin: 20px;
  height: 100px;
}

.image-layout-large {
  margin: 20px;
  height: 300px;
}

.image-grid {
  display: flex;
}

.certifications-flex-box {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.certifications-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-flex-box {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contactus-container {
  display: grid;
  padding: 30px;
  border: 1px solid #cfcfcf;
  margin-bottom: 20px;
}

.bullets-text {
  font-size: 24px;
  font-weight: bold;
}

.contactus-container-imgs {
  direction: "rtl";
}

.contactus-container-logo {
  max-height: 200px;
  max-width: 200px;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.text-title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 36px;
  color: #5b5750;
}

.contactus-text {
  margin-top: 10px;
  font-weight: bold;
  color: #5b5750;
}

.header-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.solutions-flex-box-end {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.solutions-flex-box-start {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}


@media only screen and (max-width: 1000px) {
  
  .menu {
    flex-direction: column;
    padding: 27px;
  }

  .contact-button {
    margin-top: 82px;  
  }

  .ela-protegida-container,
  .maria-penha-container,
  .partners-flex-box,
  .certifications-flex-box,
  .solutions-flex-box-end,
  .solutions-flex-box-start {
    flex-direction: column;
    padding: 10px;
    margin: 10px;
  }

  .image-layout-large {
    max-width: 100%;
    height: auto;
  }

  .bullets-text,
  .text-title,
  .contactus-text {
    font-size: 18px;
  }

  .contactus-container {
    padding: 15px;
    margin: 10px;
  }
}
