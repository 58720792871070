.container-box {
  display: grid !important;
  grid-template-columns: auto auto;
  grid-template-areas: "content ods";
  margin-top: 40px;
  margin-bottom: 40px;
}
.page-title {
  font-size: 30px;
  font-weight: bold;
  color: #5b5750;
}

.container-paper-text {
  grid-area: content;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.acelerations-box {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.container-img-box {
  grid-area: ods;
  display: flex;
  flex-direction: column;
}

.paper-img {
  width: 200px;
  padding: 10px;
}

.image-grid {
  display: flex;
}

.acelerations-image-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.acelerations-img-layout {
  max-width: 300px;
  padding-left:20px;
  max-height: 100px;
}

@media (max-width: 900px) {
  .container-img-box {
    visibility: hidden;
    display: none;
  }
}
