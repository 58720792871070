.header {
  align-items: center;
  justify-content: space-around;
  height: 100px;
  width: auto;
  display: flex;
  flex: 1;
  position: sticky;
  top: 0;
  z-index: 999;
}

a {
  text-decoration: none;
  color: black;
}

.first-itens-div {
  flex: 4.5;
  display: flex;
  align-items: center;
  justify-content: space-around;
 
}

.list-menu-item {
  color: black;
}


.list-menu-item-sobre {
  border: 1px;
  font-weight: bold;
  border-style: solid;
  border-radius: 9px;
  padding: 18px 16px 18px 16px;
  color: #7b61ff;
}


.list-menu-item:hover {
  cursor: pointer;
}

.drawer-menu-item {
  padding: 10px;
  color: #7b61ff;
}

.drawer-menu-item .drawer-last-menu-item {
  padding: 10px;
  color: white;
  background-color: #7b61ff;
}

.last-item-div {
  background-color: #7b61ff;
  top: 0;
  flex: 0.5;
  position: relative;
  height: inherit;
  display: flex;
  align-items: center;
  padding: 2px 10px;
}
.last-item-a {
  color: white;
}

.image-logo {
  height: fit-content;
  width: 280px;
  cursor: pointer;
}

.bndes-image-logo {
  height: fit-content;
  width: 60px;
  cursor: pointer;
  margin-bottom: 40px;
}

.image-div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  .header {
    display: contents;
  }
}
