.menu-error {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

.box-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
