.footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #cfcfcf;
}

.contact-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "box1 box2";
  align-items: center;
  justify-items: center;
}

.footer-title {
  font-size: 30;
  color: #5b5750;
}

.extra-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.extra-box-dot {
  margin-left: 20px;
  margin-right: 20px;
}

.side-text {
  margin-left: 10px;
  color: #5b5750;
}

.extra-box-text {
  color: #5b5750;
}

.div-link-obj {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
.box1 {
  grid-area: "box1";
}
.box2 {
  grid-area: "box2";
}
